<template>
  <!-- 会员中心 -->
  <div class="memberManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="isShowDialog = true">
          推送银行准入
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :operation-button="operationButton" :loading="loading">
        <template #state="{ row }">
          {{ $codeNew(bankStateList,row.state) }}
        </template>
        <template #bankVersion="{ row }">
          {{ $codeNew(bankVersionList,row.bankVersion) }}
        </template>
        <template #hisFlag="{ row }">
          {{ row.hisFlag==0?'有效':'失效' }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <addBankAccessDialog :is-show-dialog="isShowDialog" @handleCancel="handleCancel" @getdata="getdata" />
    <!-- <add-business-dialog :is-show-dialog="isShowDialog" @handleCancel="handleCancel" @getdata="getdata" /> -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { bankAccessList, selectFundcmp } from '@/api/memberManagement'
import addBankAccessDialog from './children/addBankAccessDialog.vue'
// import { operateArrayObj } from '@/utils/index'
export default {
  components: { FormSearch, Table, Pagination, addBankAccessDialog },
  data() {
    return {
      isShowDialog: false,
      formInline: {
        cmpName: '',
        stfundCmpUnicodeate: '',
        pageSize: 10,
        pageNum: 1
      },
      memberInformation: {},
      formItemArr: [
        { type: 'input', label: '企业名称', value: 'cmpName' },
        { type: 'select', label: '银行名称', value: 'fundCmpUnicode', pLabel: 'funderName', pValue: 'funderUnicode', child: [] }
      ],
      itemData: [
        {
          label: '会员编号',
          prop: 'cmpId',
          width: 180
        },
        {
          label: '企业名称',
          prop: 'cmpName',
          width: 140
        },
        {
          label: '关联核心企业',
          prop: 'coreCmpName',
          width: 120
        },
        {
          label: '商圈编码',
          prop: 'busiGroupCode',
          width: 180
        },
        {
          label: '银行名称',
          prop: 'groupCmpName',
          width: 180
        },
        {
          label: '准入状态',
          prop: 'state',
          width: 180
        },
        {
          label: '版本号',
          prop: 'bankVersion',
          width: 180
        },
        {
          label: '是否有效',
          prop: 'hisFlag',
          width: 180
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', withParameters: '详情', label: '详情', handleEvent: this.viewDetail },
        { bType: 'primary', withParameters: '审核', label: '审核', handleEvent: this.handleApply }
      ],
      total: 0,
      loading: false
    }
  },
  computed: {
    bankStateList() {
      return this.$store.getters.getDictionaryItem('BANK_STATE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    }
  },
  mounted() {
    this.init()
    this.getdata()
  },
  methods: {
    init() {
      selectFundcmp(res => {
        if (res.code === 200) {
          this.formItemArr[1]['child'] = res.data
          this.$forceUpdate()
        }
      })
    },
    getdata(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      bankAccessList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    handleApply(row) {
      this.$router.push({
        name: 'bankAccessManageAudit',
        query: {
          id: row.id,
          type: '2'
        }
      })
    },
    viewDetail({ id }) {
      this.$router.push({
        path: '/memberManagement/bankAccessManageDetail',
        query: { id }
      })
    },
    handleCancel(flag) {
      this.isShowDialog = flag
    }
  }
}
</script>

<style scoped>
.electronicMaterial {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.electronicMaterial img {
  height: 120px;
  width: 180px;
  border-radius: 2px;
}

.electronicMaterial a {
  color: rgb(26, 7, 136);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.el-tabs>>>.el-tabs__content {
  padding: 0 16px 32px;
}
</style>
